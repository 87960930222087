import React from 'react';
import { Line, Group, Transformer } from 'react-konva';
import useStore from '../../store/useStore';

const PencilComponent = ({ id, points, color, selected }) => {
  const lineRef = React.useRef();
  const transformerRef = React.useRef();
  const { updateElement } = useStore();

  React.useEffect(() => {
    if (selected && transformerRef.current) {
      transformerRef.current.nodes([lineRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [selected]);

  const handleTransform = () => {
    const node = lineRef.current;
    if (!node) return;

    const scaleX = node.scaleX();
    const scaleY = node.scaleY();
    
    // Reset scale
    node.scaleX(1);
    node.scaleY(1);

    // Transform points
    const newPoints = [];
    for (let i = 0; i < points.length; i += 2) {
      newPoints.push(points[i] * scaleX + node.x());
      newPoints.push(points[i + 1] * scaleY + node.y());
    }

    // Reset position
    node.x(0);
    node.y(0);

    updateElement(id, { points: newPoints });
  };

  const handleDragEnd = (e) => {
    const node = e.target;
    const dx = node.x();
    const dy = node.y();

    // Reset position
    node.x(0);
    node.y(0);

    // Move all points
    const newPoints = [];
    for (let i = 0; i < points.length; i += 2) {
      newPoints.push(points[i] + dx);
      newPoints.push(points[i + 1] + dy);
    }

    updateElement(id, { points: newPoints });
  };

  return (
    <Group>
      <Line
        id={id}
        ref={lineRef}
        points={points}
        stroke={color}
        strokeWidth={2}
        tension={0.5}
        lineCap="round"
        lineJoin="round"
        draggable
        onDragEnd={handleDragEnd}
        onTransformEnd={handleTransform}
      />
      {selected && (
        <Transformer
          ref={transformerRef}
          boundBoxFunc={(oldBox, newBox) => {
            // Limit resize
            const maxWidth = 800;
            const maxHeight = 800;
            const minWidth = 5;
            const minHeight = 5;
            const box = {
              ...newBox,
              width: Math.max(minWidth, Math.min(newBox.width, maxWidth)),
              height: Math.max(minHeight, Math.min(newBox.height, maxHeight))
            };
            return box;
          }}
        />
      )}
    </Group>
  );
};

export default PencilComponent;
