class CanvasRepository {
  constructor() {
    this.elements = new Map();
  }

  createCanvas() {
    this.elements.clear();
    return true;
  }

  addElement(element) {
    this.elements.set(element.id, element);
    return true;
  }

  updateElement(element) {
    if (!this.elements.has(element.id)) return false;
    this.elements.set(element.id, element);
    return true;
  }

  deleteElement(id) {
    return this.elements.delete(id);
  }

  getElementById(id) {
    return this.elements.get(id);
  }

  getAllElements() {
    return Array.from(this.elements.values());
  }
}

export default CanvasRepository;
