import DrawingElement from './DrawingElement';

class Text extends DrawingElement {
  constructor(id, position, text, style) {
    super(id, position, style);
    this._text = text || '';
    this.type = 'text';
  }

  get text() { return this._text; }

  setText(text) {
    this._text = text;
    return this;
  }

  toObject() {
    return {
      ...super.toObject(),
      text: this._text
    };
  }
}

export default Text;
