import React, { useState, useRef, useEffect } from 'react';
import { Text as KonvaText, Transformer } from 'react-konva';
import useStore from '../../store/useStore';

const TextComponent = ({ id, x, y, text, color, strokeWidth, selected }) => {
  const [isEditing, setIsEditing] = useState(false);
  const textRef = useRef();
  const transformerRef = useRef();
  const { updateElement } = useStore();

  useEffect(() => {
    if (selected && transformerRef.current) {
      transformerRef.current.nodes([textRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [selected]);

  const handleTransform = () => {
    const node = textRef.current;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    // Reset scale to 1 and update width/height instead
    node.scaleX(1);
    node.scaleY(1);

    updateElement(id, {
      position: { x: node.x(), y: node.y() },
      dimensions: {
        width: Math.max(5, node.width() * scaleX),
        height: Math.max(5, node.height() * scaleY)
      }
    });
  };

  const handleDblClick = () => {
    setIsEditing(true);
    const textarea = document.createElement('textarea');
    const position = textRef.current.absolutePosition();

    textarea.value = text;
    textarea.style.position = 'absolute';
    textarea.style.top = `${position.y}px`;
    textarea.style.left = `${position.x}px`;
    textarea.style.width = `${textRef.current.width() - textRef.current.padding() * 2}px`;
    textarea.style.height = `${textRef.current.height() - textRef.current.padding() * 2}px`;
    textarea.style.fontSize = `${textRef.current.fontSize()}px`;
    textarea.style.border = 'none';
    textarea.style.padding = '0px';
    textarea.style.margin = '0px';
    textarea.style.overflow = 'hidden';
    textarea.style.background = 'none';
    textarea.style.outline = 'none';
    textarea.style.resize = 'none';
    textarea.style.lineHeight = textRef.current.lineHeight();
    textarea.style.fontFamily = textRef.current.fontFamily();
    textarea.style.transformOrigin = 'left top';
    textarea.style.textAlign = textRef.current.align();
    textarea.style.color = color;

    document.body.appendChild(textarea);
    textarea.focus();

    textarea.addEventListener('keydown', function(e) {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        textarea.blur();
      }
    });

    textarea.addEventListener('blur', () => {
      updateElement(id, { text: textarea.value });
      setIsEditing(false);
      document.body.removeChild(textarea);
    });
  };

  return (
    <>
      <KonvaText
        id={id}
        ref={textRef}
        x={x}
        y={y}
        text={text}
        fontSize={16}
        fontFamily="Arial"
        fill={color}
        draggable
        onDblClick={handleDblClick}
        onDragEnd={(e) => {
          updateElement(id, {
            position: { x: e.target.x(), y: e.target.y() }
          });
        }}
        onTransformEnd={handleTransform}
        visible={!isEditing}
      />
      {selected && !isEditing && (
        <Transformer
          ref={transformerRef}
          enabledAnchors={['middle-left', 'middle-right']}
          boundBoxFunc={(oldBox, newBox) => {
            // Limit resize
            const minWidth = 20;
            if (newBox.width < minWidth) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
};

export default TextComponent;
