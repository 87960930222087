import DrawingElement from './DrawingElement';

class Connector extends DrawingElement {
  constructor(id, position, style) {
    super(id, position, style);
    this._points = [position.x, position.y, position.x, position.y];
    this._sourceId = null;
    this._targetId = null;
    this.type = 'connector';
  }

  get points() { return this._points; }
  get sourceId() { return this._sourceId; }
  get targetId() { return this._targetId; }

  setPoints(points) {
    this._points = points;
    return this;
  }

  setSource(elementId) {
    this._sourceId = elementId;
    return this;
  }

  setTarget(elementId) {
    this._targetId = elementId;
    return this;
  }

  addPoint(x, y) {
    this._points.push(x, y);
    return this;
  }

  move(dx, dy) {
    for (let i = 0; i < this._points.length; i += 2) {
      this._points[i] += dx;
      this._points[i + 1] += dy;
    }
    return super.move(dx, dy);
  }

  scale(sx, sy) {
    for (let i = 0; i < this._points.length; i += 2) {
      this._points[i] *= sx;
      this._points[i + 1] *= sy;
    }
    return this;
  }

  toObject() {
    return {
      ...super.toObject(),
      points: this._points,
      sourceId: this._sourceId,
      targetId: this._targetId
    };
  }
}

export default Connector;
