import DrawingElement from './DrawingElement';

class Path extends DrawingElement {
  constructor(id, position, style) {
    super(id, position, style);
    this._points = [position.x, position.y];
    this.type = 'pen';
  }

  get points() {
    return this._points;
  }

  setPoints(points) {
    this._points = points;
    return this;
  }

  toObject() {
    return {
      ...super.toObject(),
      points: this._points
    };
  }
}

export default Path;
