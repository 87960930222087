class Dimensions {
  constructor(width, height) {
    this._width = width;
    this._height = height;
  }

  get width() { return this._width; }
  get height() { return this._height; }

  scale(factor) {
    return new Dimensions(this._width * factor, this._height * factor);
  }

  equals(other) {
    return this._width === other.width && this._height === other.height;
  }

  toObject() {
    return { width: this._width, height: this._height };
  }
}

export default Dimensions;
