import React, { useState } from 'react';
import { Stage, Layer } from 'react-konva';
import useStore from '../store/useStore';
import Position from '../domain/models/Position';
import Dimensions from '../domain/models/Dimensions';
import ShapeComponent from './canvas/ShapeComponent';
import PencilComponent from './canvas/PencilComponent';
import TextComponent from './canvas/TextComponent';
import ConnectorComponent from './canvas/ConnectorComponent';

const Whiteboard = () => {
  const [isDrawing, setIsDrawing] = useState(false);
  const [startPos, setStartPos] = useState(null);
  const [currentShape, setCurrentShape] = useState(null);

  const {
    elements,
    selectedIds,
    activeTool,
    color,
    scale,
    createShape,
    createPath,
    createText,
    createConnector,
    updateElement,
    setSelectedIds,
    clearSelection
  } = useStore();

  const getRelativePointerPosition = (stage) => {
    const pointerPosition = stage.getPointerPosition();
    return {
      x: pointerPosition.x / scale,
      y: pointerPosition.y / scale
    };
  };

  const handleMouseDown = (e) => {
    const stage = e.target.getStage();
    if (!stage) return;

    const pos = getRelativePointerPosition(stage);

    // Handle selection tool clicks
    if (activeTool === 'select') {
      handleSelect(e);
      return;
    }

    // Only handle stage clicks for drawing tools
    if (e.target !== stage) {
      return;
    }

    setIsDrawing(true);
    setStartPos(pos);

    switch (activeTool) {
      case 'pen':
        const path = createPath(pos);
        setCurrentShape(path);
        break;
      case 'rectangle':
      case 'circle':
        const shape = createShape(
          new Position(pos.x, pos.y),
          new Dimensions(0, 0),
          { color: color },
          activeTool
        );
        setCurrentShape(shape);
        break;
      case 'text':
        const text = createText(pos, 'Double click to edit');
        setCurrentShape(text);
        setIsDrawing(false);
        break;
      case 'connector':
        const connector = createConnector(pos);
        setCurrentShape(connector);
        break;
      default:
        setIsDrawing(false);
        setCurrentShape(null);
        break;
    }
  };

  const handleMouseMove = (e) => {
    if (!isDrawing || !currentShape) return;

    const stage = e.target.getStage();
    const pos = getRelativePointerPosition(stage);

    switch (activeTool) {
      case 'pen':
        updateElement(currentShape.id, {
          points: [...currentShape.points, pos.x, pos.y]
        });
        break;
      case 'rectangle':
      case 'circle':
        const width = Math.abs(pos.x - startPos.x);
        const height = Math.abs(pos.y - startPos.y);
        const x = Math.min(pos.x, startPos.x);
        const y = Math.min(pos.y, startPos.y);
        
        updateElement(currentShape.id, {
          position: new Position(x, y),
          dimensions: new Dimensions(width, height)
        });
        break;
      case 'connector':
        updateElement(currentShape.id, {
          points: [startPos.x, startPos.y, pos.x, pos.y]
        });
        break;
      default:
        // No action needed for other tools
        break;
    }
  };

  const handleMouseUp = () => {
    setIsDrawing(false);
    setCurrentShape(null);
    setStartPos(null);
  };

  const handleSelect = (e) => {
    const clickedId = e.target.id();
    if (!clickedId) {
      clearSelection();
      return;
    }
    setSelectedIds([clickedId]);
  };

  return (
    <Stage
      width={window.innerWidth}
      height={window.innerHeight}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      scale={{ x: scale, y: scale }}
    >
      <Layer>
        {elements.map((element) => {
          switch (element.type) {
            case 'shape':
              return (
                <ShapeComponent
                  key={element.id}
                  id={element.id}
                  type={element.shapeType}
                  x={element.position.x}
                  y={element.position.y}
                  width={element.dimensions.width}
                  height={element.dimensions.height}
                  color={element.style.color}
                  strokeWidth={2}
                  selected={selectedIds.has(element.id)}
                />
              );
            case 'pen':
              return (
                <PencilComponent
                  key={element.id}
                  id={element.id}
                  points={element.points}
                  color={element.style.color}
                  strokeWidth={2}
                  selected={selectedIds.has(element.id)}
                />
              );
            case 'text':
              return (
                <TextComponent
                  key={element.id}
                  id={element.id}
                  x={element.position.x}
                  y={element.position.y}
                  text={element.text}
                  color={element.style.color}
                  selected={selectedIds.has(element.id)}
                />
              );
            case 'connector':
              return (
                <ConnectorComponent
                  key={element.id}
                  id={element.id}
                  points={element.points}
                  color={element.style.color}
                  strokeWidth={2}
                  selected={selectedIds.has(element.id)}
                />
              );
            default:
              return null;
          }
        })}
      </Layer>
    </Stage>
  );
};

export default Whiteboard;
