import React from 'react';
import { Circle, Rect, Transformer } from 'react-konva';
import useStore from '../../store/useStore';

const ShapeComponent = ({ id, type, x, y, width, height, color, strokeWidth, selected }) => {
  const shapeRef = React.useRef();
  const transformerRef = React.useRef();
  const { updateElement, activeTool } = useStore();

  React.useEffect(() => {
    if (selected && transformerRef.current) {
      transformerRef.current.nodes([shapeRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [selected]);

  const handleTransform = () => {
    const node = shapeRef.current;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    // Reset scale to 1 and update width/height instead
    node.scaleX(1);
    node.scaleY(1);

    updateElement(id, {
      position: { x: node.x(), y: node.y() },
      dimensions: {
        width: Math.max(5, node.width() * scaleX),
        height: Math.max(5, node.height() * scaleY)
      }
    });
  };

  const shapeProps = {
    id,
    ref: shapeRef,
    x,
    y,
    fill: 'transparent',
    stroke: color,
    strokeWidth,
    name: 'shape',
    draggable: activeTool === 'select',
    onDragEnd: (e) => {
      updateElement(id, {
        position: { x: e.target.x(), y: e.target.y() }
      });
    },
    onTransformEnd: handleTransform,
    onMouseEnter: (e) => {
      if (activeTool === 'connector') {
        const stage = e.target.getStage();
        stage.container().style.cursor = 'crosshair';
      }
    },
    onMouseLeave: (e) => {
      if (activeTool === 'connector') {
        const stage = e.target.getStage();
        stage.container().style.cursor = 'default';
      }
    }
  };

  return (
    <>
      {type === 'circle' ? (
        <Circle
          {...shapeProps}
          width={width}
          height={height}
          radius={Math.max(width, height) / 2}
        />
      ) : (
        <Rect
          {...shapeProps}
          width={Math.max(width, 1)}
          height={Math.max(height, 1)}
        />
      )}
      {selected && (
        <Transformer
          ref={transformerRef}
          boundBoxFunc={(oldBox, newBox) => {
            // Limit resize
            const minSize = 5;
            if (newBox.width < minSize || newBox.height < minSize) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
};

export default ShapeComponent;
