class Style {
  constructor({ color, strokeWidth, fill, fontSize } = {}) {
    this._color = color || '#000000';
    this._strokeWidth = strokeWidth || 1;
    this._fill = fill;
    this._fontSize = fontSize;
  }

  get color() { return this._color; }
  get strokeWidth() { return this._strokeWidth; }
  get fill() { return this._fill; }
  get fontSize() { return this._fontSize; }

  withColor(color) {
    return new Style({ ...this.toObject(), color });
  }

  withStrokeWidth(width) {
    return new Style({ ...this.toObject(), strokeWidth: width });
  }

  toObject() {
    return {
      color: this._color,
      strokeWidth: this._strokeWidth,
      fill: this._fill,
      fontSize: this._fontSize
    };
  }
}

export default Style;
