import create from 'zustand';
import CanvasService from '../application/CanvasService';
import Style from '../domain/models/Style';
import Position from '../domain/models/Position';
import Dimensions from '../domain/models/Dimensions';

const canvasService = new CanvasService();

const useStore = create((set, get) => ({
  elements: [],
  selectedIds: new Set(),
  activeTool: 'select',
  color: '#39FF14',
  scale: 1,

  createShape: (position, dimensions, style, shapeType) => {
    const shape = canvasService.startShape(
      position.x,
      position.y,
      dimensions,
      new Style({ color: get().color }),
      shapeType
    );
    set({ elements: canvasService.getAllElements() });
    return shape;
  },

  createPath: (position) => {
    const path = canvasService.startDrawing(
      position.x,
      position.y,
      new Style({ color: get().color })
    );
    set({ elements: canvasService.getAllElements() });
    return path;
  },

  createText: (position, text) => {
    const textElement = canvasService.startText(
      position.x,
      position.y,
      text,
      new Style({ color: get().color })
    );
    set({ elements: canvasService.getAllElements() });
    return textElement;
  },

  createConnector: (position) => {
    const connector = canvasService.startConnector(
      position.x,
      position.y,
      new Style({ color: get().color })
    );
    set({ elements: canvasService.getAllElements() });
    return connector;
  },

  updateElement: (id, updates) => {
    if (updates.dimensions) {
      updates.dimensions = new Dimensions(
        updates.dimensions.width,
        updates.dimensions.height
      );
    }
    if (updates.position) {
      updates.position = new Position(
        updates.position.x,
        updates.position.y
      );
    }
    const success = canvasService.updateElement(id, updates);
    if (success) {
      set({ elements: canvasService.getAllElements() });
    }
  },

  deleteElement: (id) => {
    const success = canvasService.deleteElement(id);
    if (success) {
      set(state => ({
        elements: canvasService.getAllElements(),
        selectedIds: new Set([...state.selectedIds].filter(selectedId => selectedId !== id))
      }));
    }
  },

  setSelectedIds: (ids) => {
    set({ selectedIds: new Set(ids) });
  },

  clearSelection: () => {
    set({ selectedIds: new Set() });
  },

  setActiveTool: (tool) => {
    set({ activeTool: tool });
  },

  setColor: (color) => {
    set({ color });
  },

  setScale: (scale) => {
    set({ scale });
  }
}));

canvasService.createCanvas();

export default useStore;
