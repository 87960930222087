class Position {
  constructor(x, y) {
    this._x = x;
    this._y = y;
  }

  get x() { return this._x; }
  get y() { return this._y; }

  translate(dx, dy) {
    return new Position(this._x + dx, this._y + dy);
  }

  equals(other) {
    return this._x === other.x && this._y === other.y;
  }

  toObject() {
    return { x: this._x, y: this._y };
  }
}

export default Position;
