import React from 'react';
import { Box, IconButton, Tooltip, Paper, Typography } from '@mui/material';
import {
  PanTool,
  Create,
  Rectangle,
  Circle,
  TextFields,
  Timeline,
  ZoomIn,
  ZoomOut,
} from '@mui/icons-material';
import { HexColorPicker } from 'react-colorful';
import useStore from '../store/useStore';

const TOOLS = [
  { id: 'select', icon: <PanTool />, tooltip: 'Select (V)' },
  { id: 'pen', icon: <Create />, tooltip: 'Pen (P)' },
  { id: 'rectangle', icon: <Rectangle />, tooltip: 'Rectangle (R)' },
  { id: 'circle', icon: <Circle />, tooltip: 'Circle (C)' },
  { id: 'text', icon: <TextFields />, tooltip: 'Text (T)' },
  { id: 'connect', icon: <Timeline />, tooltip: 'Connect (L)' },
];

const Toolbar = () => {
  const {
    activeTool,
    setActiveTool,
    scale,
    setScale,
    color,
    setColor,
  } = useStore();

  const [colorPickerOpen, setColorPickerOpen] = React.useState(false);

  const handleKeyDown = React.useCallback((e) => {
    switch (e.key.toLowerCase()) {
      case 'v': setActiveTool('select'); break;
      case 'p': setActiveTool('pen'); break;
      case 'r': setActiveTool('rectangle'); break;
      case 'c': setActiveTool('circle'); break;
      case 't': setActiveTool('text'); break;
      case 'l': setActiveTool('connect'); break;
      case '+': setScale(scale * 1.2); break;
      case '-': setScale(scale * 0.8); break;
      default: break;
    }
  }, [setActiveTool, setScale, scale]);

  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  return (
    <Paper
      elevation={3}
      sx={{
        position: 'fixed',
        bottom: 16,
        left: '50%',
        transform: 'translateX(-50%)',
        padding: 1,
        display: 'flex',
        flexDirection: 'row',
        gap: 1,
        backgroundColor: 'background.dark',
        borderRadius: 2,
        '& .MuiIconButton-root': {
          color: 'text.primary',
        },
      }}
    >
      {/* Tools */}
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 0.5 }}>
        {TOOLS.map((tool) => (
          <Tooltip key={tool.id} title={tool.tooltip} placement="top">
            <IconButton
              color={activeTool === tool.id ? 'primary' : 'default'}
              onClick={() => setActiveTool(tool.id)}
              sx={{
                backgroundColor: activeTool === tool.id ? 'action.selected' : 'transparent',
                '&:hover': {
                  backgroundColor: 'action.hover',
                },
              }}
            >
              {tool.icon}
            </IconButton>
          </Tooltip>
        ))}
      </Box>

      {/* Color Picker */}
      <Box sx={{ position: 'relative' }}>
        <Tooltip title="Color" placement="top">
          <IconButton
            onClick={() => setColorPickerOpen(!colorPickerOpen)}
            sx={{
              width: 40,
              height: 40,
              backgroundColor: color,
              '&:hover': {
                backgroundColor: color,
              },
            }}
          />
        </Tooltip>
        {colorPickerOpen && (
          <Box
            sx={{
              position: 'absolute',
              bottom: '100%',
              left: '50%',
              transform: 'translateX(-50%)',
              marginBottom: 1,
              zIndex: 1000,
              backgroundColor: 'background.paper',
              padding: 1,
              borderRadius: 1,
              boxShadow: 3,
            }}
          >
            <HexColorPicker color={color} onChange={setColor} />
          </Box>
        )}
      </Box>

      {/* Zoom Controls */}
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 0.5, alignItems: 'center' }}>
        <IconButton onClick={() => setScale(scale * 0.8)}>
          <ZoomOut />
        </IconButton>
        <Typography variant="body2" sx={{ minWidth: 40, textAlign: 'center' }}>
          {Math.round(scale * 100)}%
        </Typography>
        <IconButton onClick={() => setScale(scale * 1.2)}>
          <ZoomIn />
        </IconButton>
      </Box>
    </Paper>
  );
};

export default Toolbar;
