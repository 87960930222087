import DrawingElement from './DrawingElement';
import Dimensions from '../models/Dimensions';

class Shape extends DrawingElement {
  constructor(id, position, dimensions, style, shapeType) {
    super(id, position, style);
    this._dimensions = dimensions instanceof Dimensions ? dimensions : new Dimensions(dimensions.width, dimensions.height);
    this._shapeType = shapeType;
    this.type = 'shape';
  }

  get dimensions() {
    return this._dimensions;
  }

  get width() {
    return this._dimensions.width;
  }

  get height() {
    return this._dimensions.height;
  }

  setDimensions(dimensions) {
    this._dimensions = dimensions instanceof Dimensions ? dimensions : new Dimensions(dimensions.width, dimensions.height);
    return this;
  }

  get shapeType() {
    return this._shapeType;
  }

  toObject() {
    return {
      ...super.toObject(),
      dimensions: this._dimensions,
      shapeType: this._shapeType,
      width: this._dimensions.width,
      height: this._dimensions.height
    };
  }
}

export default Shape;
