import { v4 as uuidv4 } from 'uuid';
import CanvasRepository from '../domain/repositories/CanvasRepository';
import Shape from '../domain/entities/Shape';
import Path from '../domain/entities/Path';
import Text from '../domain/entities/Text';
import Connector from '../domain/entities/Connector';
import Position from '../domain/models/Position';
import Dimensions from '../domain/models/Dimensions';

class CanvasService {
  constructor() {
    this.repository = new CanvasRepository();
  }

  createCanvas() {
    return this.repository.createCanvas();
  }

  getAllElements() {
    return this.repository.getAllElements();
  }

  startShape(x, y, dimensions, style, shapeType) {
    const id = uuidv4();
    const position = new Position(x, y);
    const shape = new Shape(id, position, dimensions, style, shapeType);
    this.repository.addElement(shape);
    return shape;
  }

  startDrawing(x, y, style) {
    const id = uuidv4();
    const position = new Position(x, y);
    const path = new Path(id, position, style);
    this.repository.addElement(path);
    return path;
  }

  startText(x, y, text, style) {
    const id = uuidv4();
    const position = new Position(x, y);
    const textElement = new Text(id, position, text, style);
    this.repository.addElement(textElement);
    return textElement;
  }

  startConnector(x, y, style) {
    const id = uuidv4();
    const position = new Position(x, y);
    const connector = new Connector(id, position, style);
    connector.points = [x, y, x, y];
    this.repository.addElement(connector);
    return connector;
  }

  updateElement(id, updates) {
    const element = this.repository.getElementById(id);
    if (!element) return false;

    Object.entries(updates).forEach(([key, value]) => {
      if (key === 'points' && Array.isArray(value)) {
        element.setPoints(value);
      } else if (key === 'position' && value instanceof Position) {
        element.setPosition(value);
      } else if (key === 'dimensions' && value instanceof Dimensions) {
        element.setDimensions(value);
      } else if (key === 'text') {
        element.setText(value);
      } else if (key === 'source') {
        element.source = value;
      } else if (key === 'target') {
        element.target = value;
      }
    });

    return true;
  }

  deleteElement(id) {
    return this.repository.deleteElement(id);
  }

  getElementById(id) {
    return this.repository.getElementById(id);
  }
}

export default CanvasService;
