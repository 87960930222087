import Position from '../models/Position';
import Style from '../models/Style';

class DrawingElement {
  constructor(id, position, style) {
    this._id = id || Date.now().toString();
    this._position = position instanceof Position ? position : new Position(position.x, position.y);
    this._style = style instanceof Style ? style : new Style(style);
    this._selected = false;
  }

  get id() { return this._id; }
  get position() { return this._position; }
  get x() { return this._position.x; }
  get y() { return this._position.y; }
  get style() { return this._style; }
  get selected() { return this._selected; }

  setPosition(position) {
    this._position = position instanceof Position ? position : new Position(position.x, position.y);
    return this;
  }

  move(dx, dy) {
    this._position = this._position.translate(dx, dy);
    return this;
  }

  setStyle(style) {
    this._style = style instanceof Style ? style : new Style(style);
    return this;
  }

  select() {
    this._selected = true;
    return this;
  }

  deselect() {
    this._selected = false;
    return this;
  }

  toObject() {
    return {
      id: this._id,
      type: this.type || this.constructor.name.toLowerCase(),
      x: this._position.x,
      y: this._position.y,
      color: this._style.color,
      strokeWidth: this._style.strokeWidth,
      selected: this._selected
    };
  }
}

export default DrawingElement;
