import React, { useCallback } from 'react';
import { Line } from 'react-konva';
import useStore from '../../store/useStore';

const ConnectorComponent = ({ id, points = [0, 0, 0, 0], color, strokeWidth, selected }) => {
  const { elements, updateElement } = useStore();

  const updateConnectorPoints = useCallback(() => {
    const getShapeCenter = (shapeId) => {
      const shape = elements.find(el => el.id === shapeId);
      if (!shape) return null;
      return {
        x: shape.position.x + (shape.dimensions?.width || 0) / 2,
        y: shape.position.y + (shape.dimensions?.height || 0) / 2
      };
    };

    const connector = elements.find(el => el.id === id);
    if (!connector || !connector.sourceId || !connector.targetId) return;

    const sourceCenter = getShapeCenter(connector.sourceId);
    const targetCenter = getShapeCenter(connector.targetId);
    
    if (sourceCenter && targetCenter) {
      updateElement(id, {
        points: [sourceCenter.x, sourceCenter.y, targetCenter.x, targetCenter.y]
      });
    }
  }, [id, elements, updateElement]);

  React.useEffect(() => {
    updateConnectorPoints();
  }, [updateConnectorPoints]);

  // Ensure points is always an array of numbers
  const validPoints = Array.isArray(points) ? points.map(p => Number(p) || 0) : [0, 0, 0, 0];

  return (
    <Line
      id={id}
      points={validPoints}
      stroke={color}
      strokeWidth={strokeWidth}
      lineCap="round"
      lineJoin="round"
      shadowColor={selected ? 'white' : 'transparent'}
      shadowBlur={selected ? 10 : 0}
      shadowEnabled={selected}
    />
  );
};

export default ConnectorComponent;
